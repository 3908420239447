<script>
  import Card from "./Card.svelte";
  import { onMount } from "svelte";

  let team = [];
  let member = window.member;

  onMount(async () => {
    const response = await fetch("/teammember.json");
    team = await response.json();
    team = Object.values(team);
  });
</script>

{#if member}
  <Card {member} />
{:else}
  <div>
    <h1>Liste des membres de Nsigma :</h1>
    <ul>
      {#each team as member}
        <li>{member.name} ({member.role})</li>
      {/each}
    </ul>
  </div>
{/if}
