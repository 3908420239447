<script>
  import { FaAddressBook, FaShareAlt } from "svelte-icons/fa";

  export let member;
  const image = `/team/${member.id}.jpeg`;

  async function share() {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Fiche de contact NSIGMA",
          text: "Fiche de contact du ${member.role} de NSIGMA.",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Il y a eu une erreur pendant le partage", error);
      }
    } else {
      console.log("Votre navigateur ne supporte pas la Web Share API");
    }
  }
</script>

<main>
  <div class="upper">
    <div class="logo-container">
      <img src="/logo_Nsigma_2.svg" alt="Logo" />
    </div>
    <div class="rectangle">
      <div class="image-container">
        <div class="circle" style="background-image: url({image});" />
      </div>
      <div class="dark-green">
        <p class="name">{member.name}</p>
        <p class="role">{member.role}</p>
        <button class="share-container" on:click={share}>
          <div class="share-icon">
            <FaShareAlt class="share-icon" />
          </div>
          <span>Partager</span>
        </button>
      </div>
      <div class="dark-yellow">
        <p class="contact">
          <span class="icon-container">
            <FaAddressBook class="contact-icon" />
          </span>
          <a href={`/vcards/${member.id}.vcf`} download> FICHE CONTACT </a>
        </p>
      </div>
      <div class="text-container">
        <p>
          Nsigma est la Junior-Entreprise du numérique. <br />Nous nous
          engageons à vous fournir un service de qualité et un accompagnement
          adapté à vos besoins.
        </p>
      </div>
      <div class="button-container">
        <a href="tel:{member.phone}">
          <button class="button green-button">PRENDRE <br />RENDEZ-VOUS</button>
        </a>
        <a
          href="https://www.nsigma.fr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button class="button yellow-button">SITE INTERNET</button>
        </a>
        <a
          href="https://www.facebook.com/NsigmaJE/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button class="button yellow-button">PAGE FACEBOOK</button>
        </a>
        <a
          href="https://www.linkedin.com/company/nsigma-junior-enterprise/mycompany/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button class="button yellow-button">PAGE LINKEDIN</button>
        </a>
        <a
          href="https://www.instagram.com/nsigmaje/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button class="button yellow-button">PAGE INSTAGRAM</button>
        </a>
        <a href="/chemin/vers/document.pdf" download>
          <button class="button yellow-button">PLAQUETTE NSIGMA</button>
        </a>
      </div>
    </div>
  </div>
  <div class="lower" />
</main>

<style>
  :root {
    --image-size: 27vw;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  .text-container {
    text-align: center;
    color: black;
    width: 80%;
    margin: auto;
    font-size: 1em;
    padding: 10px;
  }

  .upper {
    height: 40vh;
    background-color: #65bb83;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    position: relative;
    justify-content: flex-start;
  }

  .logo-container {
    width: 30%;
    height: auto;
    margin-bottom: 4vw;
  }

  .logo-container img {
    width: 100%;
    height: auto;
  }

  .image-container {
    width: 100%;
    height: var(--image-size);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    transform: translateY(-50%);
    z-index: 3;
  }

  .circle {
    width: var(--image-size);
    height: var(--image-size);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .rectangle {
    width: 60%;
    height: 35vh;
    margin: auto;
    position: relative;
    display: flex;
    margin-top: calc(var(--image-size) / 2);
    flex-direction: column;
    z-index: 2;
  }

  .dark-green {
    padding-top: calc(var(--image-size) / 2);
    flex-grow: 2;
    flex-shrink: 2;
    flex-basis: 66.67%;
    background-color: #006633;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .dark-yellow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 33.33%;
    background-color: #f4bc22;
  }

  .lower {
    height: 60vh;
    background-color: white;
  }

  .name,
  .role,
  .contact {
    text-align: center;
  }

  .name {
    margin-top: 32px;
    font-size: 2em;
    color: #f4bc22;
  }

  .role {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #f4bc22;
  }

  .contact {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 30px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact a,
  .contact a:visited {
    color: inherit;
    text-decoration: none;
  }

  .icon-container {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
  }

  .contact-icon svg {
    max-width: 100%;
    max-height: 100%;
  }

  .share-container {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: #f4bc22;
    padding: 10px 15px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    font-size: 0.8em;
    border: none;

    cursor: pointer;
  }

  .share-icon {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 5px;
  }

  .share-icon svg {
    max-width: 100%;
    max-height: 100%;
  }

  .button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    justify-items: center;
    margin: auto;
    width: 80%;
    margin-top: 20px;
  }

  .button {
    width: 150px;
    height: 60px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 1);
    font-size: 0.6em;
  }

  .green-button {
    background-color: #006633;
    color: #f4bc22;
    font-weight: bold;
  }

  .yellow-button {
    background-color: #4eb164;
    color: black;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    :root {
      --image-size: 25vw;
    }

    .rectangle {
      width: 90%;
      height: 50vh;
      z-index: 2;
    }

    .text-container {
      width: 90%;
      font-size: 0.7em;
    }

    .logo-container {
      width: 60%;
    }

    .name {
      font-size: 1.2em;
    }

    .role {
      font-size: 1.2em;
    }

    .button {
      width: 130px;
      height: 70px;
      font-size: 0.6em;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .rectangle {
      width: 90%;
      height: 55vh;
      z-index: 2;
    }

    .text-container {
      width: 90%;
      font-size: 1.5em;
    }

    .logo-container {
      width: 60%;
    }

    .name {
      font-size: 1.2em;
    }

    .role {
      font-size: 1.2em;
    }

    .button {
      width: 30vw;
      height: 15vw;
      font-size: 1.1em;
    }
  }
</style>
